export function groupBy(key, array) {
    return Array.from(array).reduce((obj, element, index, array) => {
        let group =
            element[
                typeof key === 'function' ? key(element, index, array) : key
            ];
        if (!obj[group]) obj[group] = [element];
        else obj[group].push(element);
        return obj;
    }, {});
}

export function deepAssign(target = {}, ...sources) {
    for (let source of sources) {
        for (let key of Object.keys(source)) {
            target[key] =
                source[key]?.constructor === Object
                    ? deepAssign(target[key], source[key])
                    : source[key];
        }
    }
    return target;
}

export function flatten(object, separator = '.', prefix = null, depth = 0) {
    if (object == null || typeof object !== 'object') {
        return depth ? [[prefix, object]] : object;
    }
    let entries = Object.entries(object).flatMap(([key, value]) =>
        flatten(value, separator, (prefix?.concat(separator) ?? '') + key, depth + 1)
    );
    return depth ? entries : Object.fromEntries(entries);
}

export function transpose(matrix) {
    return matrix[0].map((_, i) => matrix.map((xs) => xs[i]));
}

export function isNumeric(object) {
    return parseFloat(object) === Number(object);
}

export function divideOrZero(dividend, divisor) {
    return divisor ? dividend / divisor : 0;
}

// For the range of available formatting options, see:
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#options
export function formatValue(
    value,
    options = {},
    locales = navigator.languages,
) {
    if (!isNumeric(value)) return value;
    let formatter = new Intl.NumberFormat(locales, options);
    return formatter.format(value);
}

export function formatDate(value, options = {}, locales = navigator.languages) {
    let date = new Date(value);
    let formatter = new Intl.DateTimeFormat(locales, options);
    return date.toJSON() ? formatter.format(date) : value;
}

export function formatDateByGranularity(
    value,
    granularity,
    locales = navigator.languages,
) {
    switch (granularity) {
        case 'year':
            return formatDate(value, { year: 'numeric' }, locales);
        case 'month':
            return formatDate(value, { month: 'short' }, locales);
        case 'week':
            return value?.toString().replace(/.*(W\d{1,2}).*/, '$1');
        default:
            return formatDate(
                value,
                { day: 'numeric', month: 'numeric' },
                locales,
            );
    }
}

export function localISODate(value = new Date()) {
    let date = new Date(value);
    return (
        date.toJSON() &&
        [
            date.getFullYear(),
            (date.getMonth() + 1).toString().padStart(2, '0'),
            date.getDate().toString().padStart(2, '0'),
        ].join('-')
    );
}

export function localISOTime(value = new Date()) {
    let date = new Date(value);
    return date.toJSON() && date.toTimeString().slice(0, 8);
}

export function localISOString(value = new Date()) {
    let date = new Date(value);
    return (
        date.toJSON() &&
        localISODate(date) +
            'T' +
            date.toTimeString().slice(0, 17).replace(' GMT', '')
    );
}

export function queryParams() {
    return Object.fromEntries(new URLSearchParams(location.search));
}

export function debounce(func, wait = 250) {
    clearTimeout(func._timeout);
    func._timeout = setTimeout(func, wait);
}

export function compare(a, b) {
    return a > b ? 1 : a < b ? -1 : 0; // cf. the 'spaceship' operator ( <=> ) in other languages
}

export function unique(array) {
    return Array.from(new Set(array));
}

export async function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export function getFractionDigits(value) {
    return (value.toString().split('.')[1] || '').length;
}
